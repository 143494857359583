.controls .macOS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 54px;
  height: 12px;
  margin-left: 0.25rem;
}

.controls .macOS-icon {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #dbe3e7;
  -webkit-box-shadow: inset 0 0 0 0.5px #ccd7dd;
          box-shadow: inset 0 0 0 0.5px #ccd7dd;
}

.controls .macOS-icon_maximize {
  background-color: #27c93f;
  -webkit-box-shadow: inset 0 0 0 0.5px #23b438;
          box-shadow: inset 0 0 0 0.5px #23b438;
}

.controls .macOS-icon_minimize {
  background-color: #ffbd2e;
  -webkit-box-shadow: inset 0 0 0 0.5px #ffb515;
          box-shadow: inset 0 0 0 0.5px #ffb515;
}

.controls .macOS-icon_close {
  background-color: #ff5f56;
  -webkit-box-shadow: inset 0 0 0 0.5px #ff473d;
          box-shadow: inset 0 0 0 0.5px #ff473d;
}

.home-how_interactive__panel {
  height: 100%;
  width: 540px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.home-how_interactive__panel .controls {
  padding: 10px;
  background-color: rgba(34, 36, 48, 0.07);
  font-family: "Menlo", "DejaVu Sans Mono", "Consolas", "Lucida Console",
    monospace;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.controls .address_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 2rem;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  width: 70%;
  border-radius: 0.25rem;
}

.address_bar .address_input {
  margin-left: 0.25rem;
  font-size: .875rem;
}

.home-how_interactive__json {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: rgba(34, 36, 48, 0.07);
  position: relative;
}

.home-how_interactive__json .main {
  margin: 0 1px 1px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 18px;
  min-height: 440px;
}
